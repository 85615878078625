.button {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background-color: #4F7FFF;
    border-radius: 6px;
    padding: 14px 20px;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    -webkit-transition: -webkit-transform .3s ease, opacity .3s ease-in;
    -webkit-transition: transform .3s ease, opacity .3s ease-in;
    transition: transform .3s ease, opacity .3s ease-in;
    margin: 15px;
}